<div class="jumbotron">
  <h1 class="text-center"> User Registration</h1>
</div>
<div *ngIf="alert" class="alert alert-warning alert-dismissible fade show" role="alert">
  <strong>Record </strong> Added Successully
  <button  type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="registeruser" >
    <div class="form-group">
      <label >Name</label>
      <input type="text" class="form-control"  formControlName="Name">
    
    </div>
   
    <div class="form-group">
        <label > Password</label>
        <input type="password" class="form-control"  formControlName="Password">
      
      </div>
      <div class="form-group">
        <label >Email address</label>
        <input type="text" class="form-control"  formControlName="Email">
      
      </div>
    <button type="submit" class="btn btn-primary">Submit</button>
  </form>