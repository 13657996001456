import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
URL="http://exam.logimetrix.info/API/quiz.php";
URLsave="http://localhost:3000/details";
qns:any[];
seconnds:number;
timer;
qnProgress:number;

  constructor(private _http:HttpClient) {

 
   }
  public getQuizQuestions():Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
           'Accept-Type':'Application/json', 
           'Access-Control-Allow-Origin': "*"
      }),
  };

  return this._http.get(this.URL,httpOptions);   
  }
 //  createUserRegistration(data)
 //  {
 //    return this._http.post(this.URLsave,data);
 //  }
  }
