import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisteruserComponent } from './registeruser/registeruser.component';
import { LoginComponent } from './login/login.component';
import { ShowquizComponent } from './showquiz/showquiz.component';
import {DashboardComponent} from './dashboard/dashboard.component';


const routes: Routes = [
  {component:RegisteruserComponent,path:'registeruser'},
  {component:LoginComponent,path:'login'},
  {component:ShowquizComponent,path:'showquiz'},
  {component:DashboardComponent,path:'dashboard'},
  {path:'', redirectTo:'/login',pathMatch:'full'}
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
