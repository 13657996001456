<nav class="navbar navbar-default navbar-fixed-top ">
  <div class="container-fluid">
    <a class="navbar-brand" href="quiz.html"><img src="../assets/images/logo.png" class="img img-responsive logo"></a>
      <button href="#" class="icon-btn" id="sidebarCollapse">
        <div class="icons-bar"></div>
        <div class="icons-bar"></div>
        <div class="icons-bar"></div>
      </button>
    <a href="login.html" class="user" data-toggle="tooltip" data-placement="bottom" title="Login"><i class="fa fa-user-circle" style="font-size:18px;"></i></a>
  </div>
</nav>	 
  
<div class="wrapper page-wrapper">
  <nav class="side-nav">
    <div class="sidebar-scroll">
      <ul>	
        <!-- <li>
          <a href="dashboard.html">
            <span><i class="fa fa-tachometer"></i></span>
            <span class="side-icons">dashboard</span>
          </a>
        </li>		   -->
        <li>
          <a href="profile.html">
            <span><i class="fa fa-user"></i></span>
            <span class="side-icons">Profile</span>
          </a>
        </li>	
        <li>
          <a routerLink="showquiz">
            <span><i class="fa fa-question-circle"></i></span>
            <span class="side-icons">Quiz</span>
          </a>
        </li>
        <li>
          <a href="quiz.html">
            <span><i class="fa fa-list-alt"></i></span>
            <span class="side-icons">Result</span>
          </a>
        </li>	
        <li>
          <a routerLink="registeruser">
            <span><i class="fa fa-list-alt"></i></span>
            <span class="side-icons">Register</span>
          </a>
        </li>	
        <li>
          <a routerLink="login">
            <span><i class="fa fa-list-alt"></i></span>
            <span class="side-icons">Login</span>
          </a>
        </li>					  
      </ul>
    </div> 
  </nav>
  <div id="main-content"><!-- main div -->

    <app-showquiz></app-showquiz>

  </div><!-- main div -->
</div> <!-- wrench -->


<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->



