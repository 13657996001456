import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisteruserComponent } from './registeruser/registeruser.component';
import { LoginComponent } from './login/login.component';
import { ShowquizComponent } from './showquiz/showquiz.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import { ProfileComponent } from './profile/profile.component';
import { ResultsComponent } from './results/results.component';
import {CommonService} from './common.service';

@NgModule({
  declarations: [
    AppComponent,
    RegisteruserComponent,
    LoginComponent,
    ShowquizComponent,
    DashboardComponent,
    ProfileComponent,
    ResultsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule

  ],
  providers: [CommonService],
  bootstrap: [AppComponent]
})
export class AppModule { }
