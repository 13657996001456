import { Component, OnInit } from '@angular/core';
import { CommonService } from './common.service';  
import { Observable } from 'rxjs';  

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';  
   testMessage$: Observable<string>;  
   constructor(private testService: CommonService) { }  
ngOnInit() {  
   this.getTestMessage();  
}  
getTestMessage () {  
    this.testService.getQuizQuestions().subscribe(data => console.log(data));  
}  
}
