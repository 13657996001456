
  <div class="quiz_page">
    <div class="row">
        <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
            <div class="quiz_page_content">
                <div class="page_head">
                    <h2>Your Quiz</h2>
                </div>
                <div class="quiz_page_detail">	
                    <div class="quiz-container">
                        <div id="quiz">
                            <div *ngFor="let question of collection.details; let i =index">
                                
                                <div *ngIf="commonService.qnProgress == i">
                              <span>{{question.id}}).{{question.question}}</span>
                                <div class="row text-left options">
                                   
                                      <div class="option">
                                        <label class="" >
                                            <input id="option1" type="radio" name="option"
                            
                                            change="onSelect(question, option);" />
                                            {{question.optiona}}
                                        </label>
                                        <br>
                                        <label class="" >
                                          <input id="option2" type="radio" name="option"
                          
                                          change="onSelect(question, option);" />
                                          {{question.optionb}}
                                      </label>
                                      <br>
                                      <label class="" >
                                        <input id="option3" type="radio" name="option"
                        
                                        change="onSelect(question, option);" />
                                        {{question.optionc}}
                                    </label>
                                    <br>
                                    <label class="" >
                                      <input id="option4" type="radio" name="option"
                      
                                      change="onSelect(question, option);" />
                                      {{question.optiond}}
                                  </label>
                                      </div>
                               
                                  </div>
                                  <button class="btn" *ngIf="commonService.qnProgress > 0" (click)="Previous()" id="previous">Previous Question</button>
                                  <button class="btn" id="next" (click)="Next()">Next Question</button>
                                  <button class="btn" id="submit">Submit Quiz</button>
                                  <div id="results"></div>
                            </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</div>

