 <div class="jumbotron">
    <h1 class="text-center"> Login</h1>
  </div>
 
  <form class="container" >
    <div class="form-group">
        <label >Email address</label>
        <input type="text" class="form-control"  name="Email" [(ngModel)]="Email">
      
      </div>
     
      <div class="form-group">
          <label > Password</label>
          <input type="password" class="form-control"  name="Password" [(ngModel)]="Password">
        
        </div>
      
      <button type="submit" class="btn btn-primary" (click)="redirectUser()">Login</button>
    </form>


    

	<!--
	 
	<style>
		/****************************************** forms start *******************************************/
		a{
			color:#335eea !important;
		}
		.login_form_page{
			z-index:1000;
			position: relative;
		}
		.login_form_page_content{
			padding:40px;
			box-sizing:border-box;
			border-radius:10px !important; 
			background: #ffffff;	
			border:8px solid rgba(51, 94, 234, 0.1);
			box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03), 0 0.9375rem 1.40625rem rgba(4,9,20,0.03), 0 0.25rem 0.53125rem rgba(4,9,20,0.05), 0 0.125rem 0.1875rem rgba(4,9,20,0.03);			
		}
		hr{
			margin-top: 12px !important;
			margin-bottom: 0px !important;
			border-top: 2px solid #00ad5f !important;
			width: 25px !important;
		}
		@media(max-width:768px){
			.login_form_page_content{
				padding:40px 20px;
			}
			.login_form_page{
				padding-top:30px;
				padding-bottom:30px;
			}
		}
		@media(min-width:768px){
			.login_form_page{
				top:50%;
				transform: translate(0%, -50%);
			}
		}
		form{
			padding-top:60px;
		}
		.login_form_page_content h2{
			color:#161c2d !important;
			font-size:20px !important;
			text-align:center;
			font-weight:600 !important;
			margin:0;
			letter-spacing: 2px;
			line-height: 30px;
		}
		.login_form_page_content .input_box{
			position:relative;
		}
		.login_form_page_content p{
			color:#838E95 !important;
			font-size:12px;
			text-align:center;
			margin-bottom:0px;
			line-height: 25px;
		}
		.login_form_page_content p a{
			color:#335eea !important;
			font-weight: 600;
		}
		.login_form_page_content .input_box label i{
			padding-right:15px !important;
		}
		.login_form_page_content .input_box input{
			width:100%;
			padding:10px 0;
			font-size:13px;
			color:#838E95;
			margin-bottom:30px;
			border:none;
			outline:none;
			background:transparent;
			border-bottom:1.3px solid rgba(131, 142, 149, 0.21);
		}
		.login_form_page_content .input_box label{
			position:absolute;
			top:0;
			letter-spacing:1px;
			left:0;
			padding:8px 0;
			font-weight:600 !important;
			font-size:12px;
			color:#838E95;
			-webkit-transition:.5s;
			-moz-transition: .5s;
			-o-transition: .5s;
			transition: .5s;
		}
		.login_form_page_content .input_box input:focus ~ label,
		.login_form_page_content .input_box input:valid ~ label{
			top:-20px;
			left:0;
			color:#00ad5f;
			font-size:12px;
		}
		.login_form_page_content .input_box input:focus{
			border-bottom:1.3px solid #00ad5f;	
		}
		.login_form_page_content input[type="submit"]:hover{
			color:#ffffff;
			background:#00ad5f;
			box-shadow: 0 -4px 0 #16965c inset;
		}
		.login_form_page_content input[type="submit"]{
			background:transparent;
			border:none;
			outline:none;
			background:rgba(0, 173, 95, 0.18);
			padding:10px 25px 12px 25px;
			cursor:pointer;
			color:#00ad5f;
			font-size: 12px;
    		font-weight: bold;
			border-radius:5px;
			box-shadow: 0 -4px rgba(22, 150, 92, 0.2) inset;
			margin-bottom: 30px;
			margin-top: 10px;
		}
		.login_form_page_content .checkbox-inline, .radio-inline{
			color:#ffffff !important;
			padding:0px 20px !important;
		}
		/****************************************** forms end *******************************************/
	</style>	

	<script>

	</script>


		<section> 
		
			<div class="container login_form_page">
				<div class="row">
					<div class="col-xs-12 col-sm-2 col-md-3 col-lg-4 col-xl-4 card_none">&nbsp;</div>
					<div class="col-xs-12 col-sm-8 col-md-6 col-lg-4 col-xl-4">
						<div class="login_form_page_content">
							<h2>Student Account Login</h2>
							<hr>
							<form action="">						
								<div class="input_box">
									<input type="text" name="" required="">
									<label><i class="fa fa-user" aria-hidden="true"></i>Mobile Number</label>
								</div>
								<div class="input_box">
									<input type="password" name="" required="">
									<label><i class="fa fa-lock" aria-hidden="true"></i>Password</label>
								</div>
						
							<input type="submit" name="" value="Login">
								<p class="account-login"><span><a href="reset.html">Forgot Password?</a></span></p> 		
								<p class="account-login">Don't have an account?<span><a href="register.html"> Create an account!</a></span></p> 					  
							</form>						  
						</div>
					</div>
					<div class="col-xs-12 col-sm-2 col-md-3 col-lg-4 col-xl-4 card_none">&nbsp;</div>
				</div>
			</div>
			<div class="overlay"></div>
		
			<svg class="bottom_wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
				<path fill="#fff" fill-opacity="1" d="M0,64L48,101.3C96,139,192,213,288,208C384,203,480,117,576,106.7C672,96,768,160,864,154.7C960,149,1056,75,1152,69.3C1248,64,1344,128,1392,160L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
			</svg>
	
		</section>	

  -->