import { Component, OnInit } from '@angular/core';
import {CommonService} from '../common.service';
import {FormGroup, FormControl} from '@angular/forms';

@Component({
  selector: 'app-registeruser',
  templateUrl: './registeruser.component.html',
  styleUrls: ['./registeruser.component.css']
})
export class RegisteruserComponent implements OnInit {
  alert:boolean=false;
registeruser=new FormGroup({
Name:new FormControl(''),
Password:new FormControl(''),
Email:new FormControl('')
})
  constructor(private register:CommonService) { }

  ngOnInit(): void {
  }
 
   
  
 
}
