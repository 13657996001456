import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
 Email:string;
 Password:string;
  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  redirectUser()
  {
   
    if(this.Email=="anuj" && this.Password=="123")
    {
      console.log(this.Email);
      this.router.navigate(["dashboard"]);
    }
    else
    {
      alert("Please enter correct details");
    }
  }
}
