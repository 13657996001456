import { Component, OnInit,Injectable } from '@angular/core';
import {CommonService} from '../common.service';


@Component({
  selector: 'app-showquiz',
  templateUrl: './showquiz.component.html',
  styleUrls: ['./showquiz.component.css']
})
@Injectable()
export class ShowquizComponent implements OnInit {
public collection:any;

  constructor(public commonService:CommonService) { }

  ngOnInit() {
    this.commonService.seconnds=0;
    this.commonService.qnProgress=0;
   this.commonService.getQuizQuestions().subscribe((result:any)=>{
     this.collection=result;
     this.commonService.qns=result;
     this.startTimer();
     console.log(result)
   });
  }
  startTimer(){
    this.commonService.timer=setInterval(()=>{
      this.commonService.seconnds++;
      
    },1000);
  }
  Next(){
  this.commonService.qnProgress++;

}
Previous(){
  this.commonService.qnProgress--;
}
}
